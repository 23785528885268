import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "NmVjNjcwMGMxYTkyNTFiZmJmMmFjNmQzMGQwMjMzNGM=",
	"Tenant-Access-Key": "6lMOV3XWbkk-0w",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers,
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
